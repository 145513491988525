(function($, window, document, jQuery, app) {
  var $win = $(window);
  var $doc = $(document);
  var winH = $win.height();
  var mobileWidth = 768;
  var isLoading = false;

  $doc.ready(function() {
    // Sticky Header - Hide on scroll down

    (function(window, document, jQuery, app) {
      var $win = $(window);
      var $doc = $(document);
      var $wrapper = $('.wrapper');
      var $html = $('html');
      var lastScrollTop = 0;

      window.app = app;

      app.initScroll = function() {
        this.headerScroll();
      };

      app.headerScroll = function() {
        /**
         * Header scroll
         */
        var $header = $('.header');
        var windowScrollTop = $win.scrollTop();
        var headerUp = 'header--up';
        var headerDown = 'header--down';
        var headerDirection = 'header--down';
        var headerDirection2 = 'header--up';

        $header.toggleClass('sticky', windowScrollTop > $header.outerHeight());

        $header.toggleClass('alt', windowScrollTop > $header.outerHeight() + 1);

        if (windowScrollTop < lastScrollTop || windowScrollTop < 0) {
          headerDirection = 'header--up';
          headerDirection2 = 'header--down';
        }

        $header.addClass(headerDirection).removeClass(headerDirection2);

        lastScrollTop = windowScrollTop;
      };
    })(window, document, window.jQuery, window.app || {});

    (function(window, document, jQuery, app) {
      var $win = $(window);
      var $doc = $(document);

      $doc.ready(function() {
        $win.on('scroll', function() {
          app.initScroll();
        });
      });
    })(window, document, window.jQuery, window.app);

    // Animate Function
    function animate(winST) {
      $('.animations').each(function() {
        if (winST + winH * 0.9 > $(this).offset().top) {
          var animClass = $(this).data('animation');

          if (!$(this).hasClass('animated')) {
            $(this).addClass('animated');
            $(this).addClass(animClass);
          }
        }
      });
    }

    $win.on('load scroll', function() {
      var winST = $win.scrollTop();

      animate(winST);
    });

    // Show hamburger menu

    $('.nav-trigger').on('click', function(event) {
      $(this).toggleClass('active');
      $('.nav ul').slideToggle();
      $('.header__logo').toggleClass('hidden');
    });

    // Mobile only

    $win.on('load resize', function() {
      if ($win.width() < mobileWidth) {
        // Hide hamburger menu on outside click

        $doc.on('click', function(event) {
          var $trigger = $('.nav');
          if ($trigger !== event.target && !$trigger.has(event.target).length) {
            $('.nav ul').slideUp();
            $('.nav-trigger').removeClass('active');
            $('.header__logo').removeClass('hidden');
          }
        });

        // Slider Cards

        if (!$('.slider-cards').hasClass('slick-initialized')) {
          $('.slider-cards').slick({
            slidesToShow: 1,
            adaptiveHeight: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="ico-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="ico-arrow-right"></i></button>'
          });
        }
      } else {
        if ($('.slider-cards').hasClass('slick-initialized')) {
          $('.slider-cards').slick('unslick');
        }
      }
    });

    $win.on('load resize', function() {
      if ($win.width() < 961) {
        $('.slider-center')
          .parent()
          .toggleClass('container-fluid')
          .removeClass('container');

        $('.container-fluid').css({
          'padding-left': '0',
          'padding-right': '0'
        });
      }
    });

    // Sliders

    // Slider Center (sides faded)

    $('.slider-center .slider__slides').slick({
      centerMode: true,
      centerPadding: '0',
      slidesToShow: 1,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            slidesToShow: 1
          }
        }
      ]
    });

    // Slider for homepage quotes

    $('.slider-quotes .slider__slides').slick({
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      adaptiveHeight: true,
      prevArrow: '<button type="button" class="slick-prev"><i class="ico-arrow-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="ico-arrow-right"></i></button>'
    });

    // Magnific Popup for Video
    $('.link-video').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });

    // Board Member Popups
    $('.open-popup-link').magnificPopup({
      type: 'inline',
      closeMarkup: '<button title="%title%" type="button" class="mfp-close">Close window <span>&#215;</span></button>',
      midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });

    $('.section__actions.events-actions').on( 'click', 'a', function(e) {
      e.preventDefault();

      if ( isLoading ) {
        return;
      }

      var nextPageLink = $(this).attr('href');
      loadPosts(nextPageLink);
    } )
  });

  function loadPosts( link ) {
    if ( isLoading ) {
      return;
    }

    var $postsHolder    = $('.section--posts');
    var $sectionActions = $('.section__actions.events-actions');

    $.ajax({
      url: link,
      type: "POST",
      beforeSend: function(){
        isLoading = true;
       },
    }).done( function(response){
      var $newPostsHolder    = $('.section--posts', response);
      var $newSectionActions = $('.section__actions.events-actions', response);
      var $newPosts          = $newPostsHolder.children();

      $postsHolder.append($newPosts);
      $sectionActions.html($newSectionActions.html());

      setTimeout(function(){
        $newPosts.each(function() {
          $(this).addClass('animated fadeInUp');
        })
      }, 1000);

      isLoading = false;
    })
  }

  $doc.on('gform_confirmation_loaded', function(event, form_id){
    $('.callout--subscribe .callout__entry, .callout--subscribe .callout__title').css('display', 'none');

    $('.callout__actions .subscribe').addClass('subscribe-complete')
  });
})(jQuery, window, document);
